a:active{
  text-decoration: none;
  outline: medium none;

}
input:hover,
input:focus,
input:active{
  text-decoration: none;
  outline: medium none;
}
ul{
  list-style:none;
  margin:0;
  padding:0;
}
header, nav, section, article, aside, footer{
  display:block;
}
body{

}

section{
  overflow: hidden;
}

.section_full{
  width: 100%;
  height: 100vh;
}
.relative{
  position: relative;
}
#trailer {
  overflow: hidden;
  height: 100vh;
}
#trailer > video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.preloader_1 {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  margin: -40px 0 0 -40px;
}
.home_container {
  background: rgba(0,0,0,0.47);
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
#home .custom_container {
  position: relative;
}
.custom_container {
  width: 1220px;
  margin: 0 auto;
  min-height: 100vh;
  height: 100%;
  padding: 0 50px;
}
.container {
  width: 1220px;
  margin: 0 auto;
}
.logo svg {
  width: 226px;
  height: 90px;
}
.h-100{
  height: 100%;
}
.w-100{
  width: 100%;
}
.header_block{
  position: absolute;
  z-index: 5;
  padding-top: 40px;
  left: 0;
  top: 0;
}
.header_bottom_block {
  position: absolute;
  z-index: 5;
  height: 50px;
  left: 0;
  bottom: 1vh;
  padding: 6px 0;
}
.soc_icon_list {
  overflow: hidden;
}
.soc_icon_list li {
  display: block;
  float: left;
  margin-right: 10px;
}
.soc_icon_list li a {
  width: 38px;
  height: 38px;
  display: block;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 38px 38px;
  margin-bottom: 10px;
}
.btn_view_video {
  height: 50px;
  line-height: 50px;
  color: #ffffff;
  font-family: 'Mont-Book';
  font-size: 16px;
  position: absolute;
  bottom: 1vh;
  left: 50%;
  cursor: pointer;
  margin-left: -10px;
  z-index: 5;
}
.btn_view_video span {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}
.btn_view_video .btn_play {
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  background-color: #00dae4;
  border-radius: 50%;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}
.btn_view_video .btn_play span {
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 13px 17px;
  background-image: url(../img/triangle.png);
  height: 100%;
  width: 100%;
  display: block;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}
.btn_view_video:hover .btn_play{
  margin: 0;
  height: 50px;
  width: 50px;
  background-image: linear-gradient(223deg, #ff3eb7 0%, #00c0c4 100%);

  span{
    background-position: 20px center;
  }
}
#homeFullVideoClose {
  color: #ffffff;
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 101;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  cursor: pointer;
  display: none;
}
#homeFullVideo {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
}

.header_center_block{
  height: 100%;
  width: 100%;
  padding: 150px 0 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
h1{
  font: 50px/52px 'Mont-Bold';
  color: #ffffff;
  margin: 0 0 50px;
}
.h1_subtitle{
  font: 22px/24px 'Mont-Bold';
  color: #ffffff;
  margin: 0 0 42px;
}

.header_links {
  color: #ffffff;
  font: 38px/40px  'Mont-Bold';
}
.link_blue_line{
  display: inline-block;
  position: relative;
  z-index: 1;
  height: 40px;
  cursor: pointer;
  color: #ffffff;
}


.link_blue_line:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.link_blue_line:after {
  content: '';
  bottom: 4px;
  width: 100%;
  height: 10px;
  z-index: -1;
  left: 0;
  position: absolute;
}

.link_blue_line:after {
  transition-delay: 0s;
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.475, .425, 0, .995);
  background: #00dae4;
  opacity: 1.0;
  transform: scale3d(1, 1, 1);
  transform-origin: bottom
}

.link_blue_line:hover:after {
  transform: scale3d(1, 3.2, 1)
}
.section_inset{
  padding: 50px 0 200px;
  height: 100%;
}
.section_flex{
  display: flex;
  align-items: center;
  height: 100%;
}

#description{
  background-color: #060e2a;
}

.h2{
  font: 42px/40px 'Mont-Bold';
  color: #ffffff;
  margin: 0 0 50px;
}
.h3{
  font: 30px/40px 'Mont-Bold';
  color: #ffffff;
  margin: 0 0 50px;
}
.h2_blue_line{
  height: 40px;
  position: relative;
  display: inline-block;
}


.h2_blue_line:hover {
  color: #ffffff;
  text-decoration: none;
}

.h2_blue_line:after {
  content: '';
  bottom: 4px;
  width: 100%;
  height: 10px;
  z-index: 1;
  left: 0;
  position: absolute;
}

.h2_blue_line:after {
  transition-delay: 0s;
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.475, .425, 0, .995);
  background: #00dae4;
  opacity: 1.0;
  transform: scale3d(1, 1, 1);
  transform-origin: bottom
}
.h2_blue_line span{
  position: relative;
  z-index: 2;
}

#description h2{
  margin-bottom: 100px;
}
.description{
  font: 20px/30px 'Mont-Book';
  color: #ffffff;
}
.description p{
  margin-bottom: 30px;
}
.mont_bold{
  font-family: 'Mont-Bold';
}


#royalty{
  background-color: #060e2a;
}
#royalty h2{
  margin-bottom: 100px;
}
.royalty_list{
  width: 55%;

}
.royalty_list li{
  position: relative;
  margin-bottom: 45px;
  padding-left: 75px;

  &:last-child{
    margin: 0;
  }

  &.num1:before{
    content: '';
    width: 20px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 6px;
    background: url("../img/roy1.jpg") no-repeat 0 0;
    background-size: 20px auto;
  }
  &.num2:before{
    content: '';
    width: 20px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 6px;
    background: url("../img/roy2.jpg") no-repeat 0 0;
    background-size: 20px auto;
  }
  &.num3:before{
    content: '';
    width: 20px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 6px;
    background: url("../img/roy3.jpg") no-repeat 0 0;
    background-size: 20px auto;
  }
}
.royalty_text_title{
  font: 22px/30px 'Mont-Bold';
  color: #ffffff;
  margin-bottom: 15px;
}
.royalty_item_text{
  font: 18px/22px 'Mont-Book';
  color: #ffffff;
}

#banner{
  background-image: radial-gradient(circle at 0 50%, #00f4ff, #c11a9d 100%);

}
.banner_wrapper{
  height: 200px;
  background: url(../img/wave_.svg) no-repeat 0 bottom;
  background-size: 100% auto;
}
.banner_text{
  font: 18px/18px 'Mont-Bold';
  color: #ffffff;
  padding-top: 40px;
  margin: 0 0 56px;
}
.btn_banner{
  height: 62px;
  padding: 0 30px;
  border: 2px solid #ffffff;
  font: 30px/58px 'Mont-Bold';
  color: #ffffff;
  transition: 0.3s all;
  display: inline-block;

  &:hover,
  &:focus{
    background: #ffffff;
    color: #00dae4;
    text-decoration: none;
  }
}
.right_space {
  padding-right: 160px;
}

#buy_royalty{
  background-color: #060e2a;
}

.section_flex_around{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
}
#buy_royalty h3{
  margin: 0;
}
.br_items{
  display: flex;
  justify-content: space-between;
}
.br_item{
  width: 30%;
  border: 1px solid #01d5df;
  font: 18px/24px 'Mont-Book';
  color: #ffffff;
  padding: 20px;
}
.cf_items{
  display: flex;
}
.cf_item{
  display: flex;
  align-items: center;
  width: 50%;
}
.cf_item img{
  width: 86px;
  height: 86px;
  margin-right: 40px;
}
.cf_item .text{
  width: 340px;
  font: 18px/22px 'Mont-Book';
  color: #ffffff;
}

#how_it_works{
  background-image: linear-gradient(to bottom, #070f2a, #068a94);
}
.hiw_blocks{
  display: flex;
  justify-content: space-between;
}
.hiw_block{
  width: 240px;
  height: 240px;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 10px;
  position: relative;
}
.hiw_block_small{
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: center;
  font: 22px/28px 'Mont-Bold';
  color: #181818;
}
.hiw_arrows{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
.hiw_arrow_left{
  height: 105px;
  width: 1px;
  background: #ffffff;
  transform: rotate(-40deg);
}
.hiw_arrow_center{
  height: 80px;
  width: 1px;
  background: #ffffff;
  margin: 0 240px;
}
.hiw_arrow_right{
  height: 105px;
  width: 1px;
  background: #ffffff;
  transform: rotate(40deg);
}
.hiw_button{
  display: flex;
  justify-content: center;
}
.btn_hiw{
  width: 380px;
  height: 84px;
  border: 1px solid #ffffff;
  text-align: center;
  font: 22px/82px 'Mont-Bold';
  color: #ffffff;
}
.hiw_block_big{
  width: 400px;
  height: 400px;
  display: none;
  position: absolute;
  z-index: 10;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font: 17px/22px 'Mont-Book';
  color: #181818;
  padding: 65px;
  text-align: justify;
}
.hiw_block_big_1{
  top: -20px;
  left: -40px;
}
.hiw_block_big_2{
  top: -20px;
  left: -80px;
}
.hiw_block_big_3{
  top: -20px;
  right: -40px;
}
.hiw_block:hover .hiw_block_big{
  display: block;
}
.partners_block{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 90px;
}
.partners_block a{
  margin-right: 90px;
}
.partners_block a:last-child{
  margin: 0;
}
.h_dark{
  color: #181818;
}
.subscribe {
  margin-top: 100px;
  width: 521px;
}

.subscribe__success {
  margin-top: 92px;
  font-family: 'Mont-Book';
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 26px;
  text-transform: uppercase;
  display: none;
}

.ui-button {
  cursor: pointer;
  user-select: none;
  display: block;
  width: 145px;
  height: 26px;
  font-family: 'Mont-Book';
  font-size: 30px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
}

.ui-button_textonly {
  font-family: 'Mont-Book';
  font-size: 30px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
}

.ui-button_light {
  color: #ffffff;
}

.ui-button_light:hover {
  color: #ff3eb7;
}

.ui-textbox__input, .ui-textbox__textarea {
  background: none;
  border: none;
  border-bottom-color: rgba(0,0,0,.12);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 0;
  color: rgba(0,0,0,.87);
  cursor: auto;
  display: block;
  font-family: Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  outline: none;
  padding: 0;
  -webkit-transition: border .1s ease;
  transition: border .1s ease;
  width: 100%;
}
.ui-textbox {
  align-items: flex-start;
  display: flex;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-bottom: calc(8px);
  &:hover:not(.is-disabled) {
    .ui-textbox__label-text {
      color: rgba(black, 0.75);
    }
    .ui-textbox__input,
    .ui-textbox__textarea {
      border-bottom-color: rgba(black, 0.3);
    }
  }
  &.is-active:not(.is-disabled) {
    .ui-textbox__input,
    .ui-textbox__textarea {
      border-bottom-color: #2196f3;
      border-bottom-width: 2px;
    }
    .ui-textbox__label-text,
    .ui-textbox__icon-wrapper .ui-icon {
      color: #2196f3;
    }
  }
  &.has-label {
    .ui-textbox__icon-wrapper {
      padding-top: calc(24px);
    }
  }
  &.has-counter {
    .ui-textbox__feedback-text {
      padding-right: calc(48px);
    }
  }
  &.has-floating-label {
    .ui-textbox__label-text {
      // Behaves like a block, but width is the width of its content.
      // Needed here so label doesn't overflow parent when scaled.
      display: table;
      &.is-inline {
        color:  rgba(black, 0.54); // So the hover styles don't override it
        cursor: text;
        transform: translateY(calc(26px)) scale(1.1);
      }
      &.is-floating {
        transform: translateY(0) scale(1);
      }
    }
  }
  &.is-invalid:not(.is-disabled) {
    .ui-textbox__label-text,
    .ui-textbox__icon-wrapper .ui-icon,
    .ui-textbox__counter {
      color: #f44336;
      font-weight: normal;
      font: 18px/26px 'Mont-Book'
    }
    .ui-textbox__input,
    .ui-textbox__textarea {
      border-bottom-color: #f44336;
    }
    .ui-textbox__feedback {
      color: #f44336;
    }
  }
  &.is-disabled {
    .ui-textbox__input,
    .ui-textbox__textarea {
      border-bottom-style: dotted;
      border-bottom-width: 2px;
      color: rgba(black, 0.38);
    }
    .ui-textbox__icon-wrapper .ui-icon {
      opacity: 0.6;
    }
    .ui-textbox__feedback {
      opacity: 0.8;
    }
  }
}
.ui-textbox__label {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: normal;
}
.ui-textbox__icon-wrapper {
  flex-shrink: 0;
  margin-right: calc(12px);
  padding-top: calc(4px);
  .ui-icon {
    color: rgba(black, 0.54);
  }
}
.ui-textbox__content {
  flex-grow: 1;
}
.ui-textbox__label-text {
  color: rgba(black, 0.54);
  cursor: default;
  font-size: calc(15px);
  line-height: normal;
  margin-bottom: 0;
  transform-origin: left;
  transition: color 0.1s ease, transform 0.2s ease;
}
.ui-textbox__input,
.ui-textbox__textarea {
  background: none;
  border: none;
  border-bottom-color: rgba(black, 0.12);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 0;
  color: rgba(black, 0.87);
  cursor: auto;
  display: block;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: calc(16px);
  font-weight: normal;
  margin: 0;
  outline: none;
  padding: 0;
  transition: border 0.1s ease;
  width: 100%;
}
.ui-textbox__input {
  height: calc(32px);
}
.ui-textbox__textarea {
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: calc(6px);
  resize: vertical;
}
.ui-textbox__feedback {
  color: rgba(black, 0.54);
  font-size: calc(14px);
  line-height: 1.2;
  margin: 0;
  padding-top: calc(4px);
  position: relative;
}
.ui-textbox__counter {
  position: absolute;
  right: 0;
  top: calc(4px);
}
// ================================================
// Icon position
// ================================================
.ui-textbox--icon-position-right {
  .ui-textbox__icon-wrapper {
    margin-left: calc(8px);
    margin-right: 0;
    order: 1;
  }
}
.ui-textbox__label-text {
  color: #ffffff !important;
  text-transform: uppercase;
  font: 18px/26px 'Mont-Book';
}
.ui-textbox__input {
  color: #ffffff !important;
  border-bottom-color: #ffffff !important;
}
#contact_us{
  background: #060e2a;
  padding: 80px 0 70px;
}

.contact_label{
  font: 18px/22px 'Mont-Book';
  color: #ffffff;
  margin-bottom: 10px;
}
.eb_link{
  font: 22px/22px 'Mont-Book';
  color: #00dae4;

  &:hover{
    color: #00dae4;
    text-decoration: none;
  }
}
.contact_info{
  margin-bottom: 80px;
}
#subscribe{
  position: relative;
}
.btn_send_subscribe {
  position: absolute;
  right: -22px;
  top: 26px;
  font: 22px/22px 'Mont-Book';
  color: #ffffff;
  display: block;
}


.footer .container {
  max-width: 1440px;
  padding: 0 80px;
}

.footer {
  padding-top: 50px;
  padding-bottom: 20px;
}

.link {
  display: inline-block;
  margin-right: 24px;
  color: #000000;
  font-family: 'Mont-Book';
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-transform: uppercase;
}
.link.link_page{
  font-size: 16px;
  text-transform: none;
}

.link_social {
  color: #00dae4;
}
.footer{
  .soc_icons {
    position: static !important;
    margin-bottom: 15px;

    .soc_icon_list {
      a {

        svg{
          opacity: 1;

          .with_stroke{
            transition: 0.3s all;
          }

          .with_fill{
            transition: 0.3s all;
          }
        }

        &:hover{

          svg{
            opacity: 1;

            .with_stroke{
              stroke: #00dae4;
            }

            .with_fill{
              fill: #00dae4;
            }
          }
        }

        svg {
          opacity: 1;

          .with_stroke {
            stroke: #333;
          }

          .with_fill {
            fill: #333;
          }
        }

      }
    }
  }
}
#footer_copyright, .footer_text {
  font-size: 16px;
  font-family: 'Mont-Book';
  padding-top: 10px;
  text-transform: uppercase;
}
.link.link_page{
  color: #333;
}
.privacy_block{
  text-align: right;
}

.will-fade-in {
  transition: opacity 1s, transform 1s;
  opacity: 0;
  transform: translate3d(0, 100px, 0);
}
.will-fade-in2 {
  transition: opacity 1s;
  opacity: 0;
}
.will-fade-in3 {
  transition: opacity 1s;
  transition-delay: 0.5s;
  opacity: 0;
}